// @ts-check
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 *
 * @param {Object} props Component dropProps
 * @param {Object} props.faIcon The associated fontawesome icon
 * @param {number} [props.iconSizePx] The icon size i pixel
 * @param {string} [props.iconColor] The icon color
 * @param {string} [props.backgroundColor] The background color
 * @param {(...args: any[]) => any} [props.onClick] The click callback
 * @returns
 */
const RoundedButton = ({
  faIcon,
  iconSizePx = 14,
  iconColor = THEME_COLORS.white,
  backgroundColor = 'accent',
  onClick
}) => {
  const sizePx = `${iconSizePx}px`;
  return (
    <Box
      round
      background={backgroundColor}
      pad="xsmall"
      align="center"
      justify="center"
      focusIndicator
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faIcon} color={iconColor} style={{ width: sizePx, height: sizePx }} />
    </Box>
  );
};

export default RoundedButton;
